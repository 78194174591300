/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SummaryGameResponse,
    SummaryGameResponseFromJSON,
    SummaryGameResponseFromJSONTyped,
    SummaryGameResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SummaryRoundResponse
 */
export interface SummaryRoundResponse {
    /**
     * 
     * @type {number}
     * @memberof SummaryRoundResponse
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof SummaryRoundResponse
     */
    readonly name?: string | null;
    /**
     * 
     * @type {Array<SummaryGameResponse>}
     * @memberof SummaryRoundResponse
     */
    readonly games?: Array<SummaryGameResponse> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SummaryRoundResponse
     */
    readonly points?: Array<number> | null;
}

export function SummaryRoundResponseFromJSON(json: any): SummaryRoundResponse {
    return SummaryRoundResponseFromJSONTyped(json, false);
}

export function SummaryRoundResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryRoundResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'games': !exists(json, 'games') ? undefined : (json['games'] === null ? null : (json['games'] as Array<any>).map(SummaryGameResponseFromJSON)),
        'points': !exists(json, 'points') ? undefined : json['points'],
    };
}

export function SummaryRoundResponseToJSON(value?: SummaryRoundResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


