import React from 'react'

function Card({colwidth, card, onCardClicked, colwidthSmallScreen, stackNumber}) {
  const onClick = () => {
    if(!!onCardClicked && !!card.id) onCardClicked(card.id, stackNumber);
  }

  const className = "card" + (!!card.value ? " card-hornox-" + card.numHornOx : (card.isNoSlot ? " card-no-slot" : (card.isEmptySlot ? " card-empty-slot" : ""))) + ((!onCardClicked || !card.id) ? "" : " clickable")
  const cardBox = "card-box " + (colwidthSmallScreen !== undefined ? "col-lg-" + colwidth + " col-" + colwidthSmallScreen : "col-" + colwidth);
  const lastPlayed = card.playedBy === undefined ? "" : <div style={{position: 'absolute', paddingLeft: '3px'}}>{card.playedBy.name.substring(0,1)}</div>

  var hornochsen = "";
  if (card.numHornOx === 7) hornochsen = <>👹👹👹👹<br/>👹👹👹</>;
  else if (card.numHornOx === 5) hornochsen = <>👹👹👹<br/>👹👹</>;
  else for (let i = 0; i < card.numHornOx; i++) { hornochsen += "👹" }

  return (
    <div className={cardBox} onClick={onClick}>
      <div className={className}>
        <div className="container full-height">
          <div className="row full-height">{lastPlayed}
            
            <div className="full-width hornochsen">{hornochsen}</div>
            <div className="align-self-center card-value full-width">{card.value}</div>
            <div style={{marginTop : "auto"}} className="full-width rotate-180 hornochsen">{hornochsen}</div>
          
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
