import React, { Component } from 'react'

// input props:
//   > data = {value}
//   > onChange(data) => Callback update props.data in parent
//  


export class Checkbox extends Component {

  render() {
    return (
      <div className="form-group">
        <div className="checkbox">
          <label>
            <input type="checkbox" defaultChecked={this.props.data.value} onChange={(e) => this.props.onChange({ value : e.currentTarget.checked})} />&nbsp;{this.props.children}
          </label>
        </div>
      </div>
    )
  } // render()

} // export class Checkbox extends Component

export default Checkbox
