import React, { Component } from 'react'
import validator from 'validator';

// input props:
//   > data = {value, valid}
//   > onChange(data) => Callback update props.data in parent
//  

const emailNotValid = "Email address not valid!";

export class Email extends Component {

  constructor(props) {
    super(props);

    let valid = validator.isEmail(props.data.value);
    let showValidation = !(props.data.value == null || props.data.value === "");
    let validationText = (valid || !showValidation ? null : emailNotValid);

    this.state = {
      strValidationText : validationText,
      blnShowValidation : showValidation,
    };

    if(valid !== props.data.valid) {
      props.onChange(props.data.valid, valid);
    }
  } // constructor(props)


  doValidation = (email, blnShowValidation) => {
    let valid = validator.isEmail(email);

    if (typeof blnShowValidation === 'undefined') {
      this.setState({
        strValidationText : (valid || !this.state.blnShowValidation ? null : emailNotValid),
      });

    } else {
      this.setState({
        strValidationText : (valid ? null : emailNotValid),
        blnShowValidation : blnShowValidation,
      });

    } 

    this.props.onChange({ valid : valid, value : email});
  } // doValidation = (email, blnShowValidation)




  render() {
    return (
      <div className="form-group">
        <label>Email</label>
        <input className="form-control" type="text" value={this.props.data.value || ""} onChange={(e) => this.doValidation(e.target.value)} onBlur={(e) => this.doValidation(e.target.value, true)}/>
        <span className="text-danger field-validation-valid">{this.state.strValidationText}</span>
      </div>
    )
  } // render()

} // export class Email extends Component

export default Email
