import React, { Component } from 'react'
// input props:
//   > data = {value, valid}
//   > onChange(data) => Callback update props.data in parent
//   > [Label] => The label of this form element

const notValid = "The username must not be empty";

export class Username extends Component {

  constructor(props) {
    super(props);

    let valid = props.data.value && props.data.value.length >= 1;

    let showValidation = !(props.data.value == null || props.data.value === "");
    let validationText = (valid || !showValidation ? null : notValid);

    this.state = {
      strValidationText : validationText,
      blnShowValidation : showValidation,
    };

    if(valid !== props.data.valid) {
      props.onChange(props.data.value, valid);
    }
  } // constructor(props)


  doValidation = (username, blnShowValidation) => {
    let valid = username && username.length >= 1  ;

    if (typeof blnShowValidation === 'undefined') {
      this.setState({
        strValidationText : (valid || !this.state.blnShowValidation ? null : notValid),
      });

    } else {
      this.setState({
        strValidationText : (valid ? null : notValid),
        blnShowValidation : blnShowValidation,
      });

    } 

    this.props.onChange({ valid : valid, value : username});
  } // doValidation = (username, blnShowValidation)




  render() {
    return (
      <div className="form-group">
        <label>{this.props.label !== undefined ? this.props.label : "Username"}</label>
        <input className="form-control" type="text" value={this.props.data.value || ""} onChange={(e) => this.doValidation(e.target.value)} onBlur={(e) => this.doValidation(e.target.value, true)}/>
        <span className="text-danger field-validation-valid">{this.state.strValidationText}</span>
      </div>
    )
  } // render()

} // export class Username extends Component

export default Username
