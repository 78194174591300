import { Configuration } from "./runtime";
import authServics from "../authorization/AuthorizeService";
import { backendConfig } from "./config";
import * as Apis from "./apis";

export class ApiBackendClass {
  // CODEGEN <Declaring>
  public Accounts : Apis.AccountsApi;
  public Auth : Apis.AuthApi;
  public Game : Apis.GameApi;
  public Roles : Apis.RolesApi;
  public Session : Apis.SessionApi;
  public Team : Apis.TeamApi;
  public Teams : Apis.TeamsApi;
  public Version : Apis.VersionApi;
  // CODEGEN </Declaring>

  constructor() {
    let configuration = new Configuration({ ...backendConfig,
      "accessToken" : authServics.getAccessToken,
    });

    // CODEGEN <Instantiating>
    this.Accounts = new Apis.AccountsApi(configuration);
    this.Auth = new Apis.AuthApi(configuration);
    this.Game = new Apis.GameApi(configuration);
    this.Roles = new Apis.RolesApi(configuration);
    this.Session = new Apis.SessionApi(configuration);
    this.Team = new Apis.TeamApi(configuration);
    this.Teams = new Apis.TeamsApi(configuration);
    this.Version = new Apis.VersionApi(configuration);
    // CODEGEN </Instantiating>
  } // constructor

} // export class ApiBackendClass


const apiBackend = new ApiBackendClass();
export default apiBackend;