import React from 'react'
import RoundListElement from './RoundListElement'
import SechsNimmt from '../api-backend/ApiBackend'

function SessionListElement({session, team}) {
  const onCreateRound = () => SechsNimmt.Session.CreateRound({teamId : team.id})

  return (
    <>
      <div className="list-group-item">
        <h5>{session.date}</h5>
        {team.isOwner && session.isTodaysSession && !session.hasRunningRound ? (
          <div className="list-group-item">
            <button type="button" className="btn btn-success" onClick={onCreateRound}>Start new Round</button>
          </div>) : <></>}
        <div className="list-group">
          {session.rounds.map((round) => (<RoundListElement key={round.id} round={round} teamId={team.id}/>))}
        </div>
      </div>
    </>
  )
}

export default SessionListElement