/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetailedCardResponse,
    DetailedCardResponseFromJSON,
    DetailedCardResponseFromJSONTyped,
    DetailedCardResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedPileResponse
 */
export interface DetailedPileResponse {
    /**
     * 
     * @type {Array<DetailedCardResponse>}
     * @memberof DetailedPileResponse
     */
    readonly cards?: Array<DetailedCardResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedPileResponse
     */
    key?: number;
}

export function DetailedPileResponseFromJSON(json: any): DetailedPileResponse {
    return DetailedPileResponseFromJSONTyped(json, false);
}

export function DetailedPileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedPileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cards': !exists(json, 'cards') ? undefined : (json['cards'] === null ? null : (json['cards'] as Array<any>).map(DetailedCardResponseFromJSON)),
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function DetailedPileResponseToJSON(value?: DetailedPileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
    };
}


