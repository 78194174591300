import React, { Component } from 'react'

export default class DataProtection extends Component {
  render() {
    return (
      <>
        <h1>Datenschutz</h1>
        <p/>
        <h3>Server-Log-Dateien</h3>
        <p>Die Website wird auf einem Server der Firma netcup GmbH betrieben. Der Betreiber erstellt dabei Log-Files mit einigen Informationen zur Verbindung und wertet diese unter anderem für einen Sicheren Betrieb der Website aus. Aus der <a href="https://www.netcup.de/kontakt/datenschutzerklaerung.php">Datenschutzerklärung der netcup.de Website</a> kann unter Kapitel III entnommen werden auf welcher juristischen Grundlage dies stattfindet und wie lange diese Daten gespeichert werden.</p>
        <p>Auch für den Betrieb der Website werden Verbindungsdaten gespeichert um einen sicheren Betrieb der Anwendung sicherzustellen. Die Daten werden ausschießlich zur Analyse und Behebung von potentiellen Problemen oder Fehlern verwendet. Im Rahmen dieser Log-Dateien werden jedoch keine personenbezogenen Daten verarbeitet sondern lediglich die URL und die HTTP-Methode abgespeichert.</p>        

        <h3>Cookies und Session/Local Storage</h3>
        <p>Die Website verwendet keine Marketing Cookies oder ähnliches. Es werden lediglich im localen Speicher des Browsers (Session/Local Storage) Zugriffsschlüssel abgelegt, die für die Authentifizierung des Anwenders notwendig sind.</p>

        <h3>Personenbezogenen Daten</h3>
        <p>Informationen die im Rahmen der Anwendung mit Ihrem Account verknüpft werden (z.B. Email, Username, Passwort, ...) werden in einer Datenbank auf dem Server gespeichert und als Sicherungskopie regelmäßig verschlüsselt und auf einen anderen Server kopiert.</p>
        <p>Falls Sie Auskunft über die zu Ihrem Zugang gespeicherten Daten möchten oder Ihre personenbezogenen Daten löschen lassen möchten, bitte ich Sie Ihre Anfrage an <a href="mailto:Datenschutz@schumacher-bw.de">Datenschutz@schumacher-bw.de</a> zu stellen.</p>
      </>
    )
  }
}
