import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

import { ApplicationPaths } from '../ApiConstants';

import TeamList from './TeamList'
import SechsNimmt from '../api-backend/ApiBackend'

const Teams = () => {
  const [teams, setTeams] = useState({ YourTeams : [], OpenTeams : [] })
  const [loading, setLoading] = useState(true)

  // Initial load the teams, then periodically update
  useEffect(() => {
    const doAsync = async () => {
      const data = await SechsNimmt.Teams.get();

      setTeams(data)
      setLoading(false)
    }

    const interval=setInterval(()=>{ doAsync() },2000)
    return()=>clearInterval(interval)

  }, [])



  return (
    <>
      {loading ? (<p><em>Loading...</em></p>) : (
        <>
          { typeof teams.myTeams === 'undefined' || teams.myTeams.length === 0 ? "" : 
            <TeamList title='My teams' teams={teams.myTeams} />
          }

          { typeof teams.openTeams === 'undefined' || teams.openTeams.length === 0 ? "" : 
            <TeamList title='Open teams' teams={teams.openTeams} />
          }

          <Link to={ApplicationPaths.Team.Create} className="btn btn-primary">Create Team</Link>
        </>)
      }
    </>
  )
}

export default Teams
