/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryTeamResponse
 */
export interface SummaryTeamResponse {
    /**
     * 
     * @type {number}
     * @memberof SummaryTeamResponse
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof SummaryTeamResponse
     */
    readonly name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SummaryTeamResponse
     */
    readonly isOwner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SummaryTeamResponse
     */
    readonly isMember?: boolean;
}

export function SummaryTeamResponseFromJSON(json: any): SummaryTeamResponse {
    return SummaryTeamResponseFromJSONTyped(json, false);
}

export function SummaryTeamResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryTeamResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isOwner': !exists(json, 'isOwner') ? undefined : json['isOwner'],
        'isMember': !exists(json, 'isMember') ? undefined : json['isMember'],
    };
}

export function SummaryTeamResponseToJSON(value?: SummaryTeamResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


