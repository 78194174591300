import React from 'react'
import Card from './Card'

function GameField ({gamePiles, onTakePile}) {
  return (
    <div className="jumbotron">
      <h4>GameField</h4>
      { !gamePiles ? <p><em>Loading...</em></p> : (
        <>
          {gamePiles.map((gamePile) => (<GamePile key={gamePile.key} gamePile={gamePile} onTakePile={onTakePile}/>))}
        </>
      )}
    </div>
  )
}

function GamePile({gamePile, onTakePile}) {
  return (
    <div className="row no-margin">
      {gamePile.cards.map((card) => (<Card colwidth="2" key={card.key} card={card} onCardClicked={onTakePile} stackNumber={gamePile.key} />))}
    </div>
  )
}

export default GameField