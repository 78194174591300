/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SummaryTeamResponse,
    SummaryTeamResponseFromJSON,
    SummaryTeamResponseToJSON,
} from '../models';

export interface SessionApiCreateRoundteamIdRequest {
    teamId: number;
}

/**
 * 
 */
export class SessionApi extends runtime.BaseAPI {

    /**
     */
    async createRoundteamIdRaw(requestParameters: SessionApiCreateRoundteamIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SummaryTeamResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling createRoundteamId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Session/createRound/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryTeamResponseFromJSON(jsonValue));
    }

    /**
     */
    async createRoundteamId(requestParameters: SessionApiCreateRoundteamIdRequest, initOverrides?: RequestInit): Promise<SummaryTeamResponse> {
        const response = await this.createRoundteamIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
