/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DetailedGameActionsResponse
 */
export interface DetailedGameActionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DetailedGameActionsResponse
     */
    readonly playGameCard?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedGameActionsResponse
     */
    readonly selectGameStack?: boolean | null;
}

export function DetailedGameActionsResponseFromJSON(json: any): DetailedGameActionsResponse {
    return DetailedGameActionsResponseFromJSONTyped(json, false);
}

export function DetailedGameActionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedGameActionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playGameCard': !exists(json, 'playGameCard') ? undefined : json['playGameCard'],
        'selectGameStack': !exists(json, 'selectGameStack') ? undefined : json['selectGameStack'],
    };
}

export function DetailedGameActionsResponseToJSON(value?: DetailedGameActionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


