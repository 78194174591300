/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetailedCardResponse,
    DetailedCardResponseFromJSON,
    DetailedCardResponseFromJSONTyped,
    DetailedCardResponseToJSON,
    DetailedGameActionsResponse,
    DetailedGameActionsResponseFromJSON,
    DetailedGameActionsResponseFromJSONTyped,
    DetailedGameActionsResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedMyselfResponse
 */
export interface DetailedMyselfResponse {
    /**
     * 
     * @type {Array<DetailedCardResponse>}
     * @memberof DetailedMyselfResponse
     */
    readonly handCards?: Array<DetailedCardResponse> | null;
    /**
     * 
     * @type {DetailedGameActionsResponse}
     * @memberof DetailedMyselfResponse
     */
    requiredActions?: DetailedGameActionsResponse;
}

export function DetailedMyselfResponseFromJSON(json: any): DetailedMyselfResponse {
    return DetailedMyselfResponseFromJSONTyped(json, false);
}

export function DetailedMyselfResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedMyselfResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'handCards': !exists(json, 'handCards') ? undefined : (json['handCards'] === null ? null : (json['handCards'] as Array<any>).map(DetailedCardResponseFromJSON)),
        'requiredActions': !exists(json, 'requiredActions') ? undefined : DetailedGameActionsResponseFromJSON(json['requiredActions']),
    };
}

export function DetailedMyselfResponseToJSON(value?: DetailedMyselfResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requiredActions': DetailedGameActionsResponseToJSON(value.requiredActions),
    };
}


