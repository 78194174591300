import React from 'react'
import TeamListElement from './TeamListElement'

function TeamList({teams, title}) {
  return (
    <>
      <h2>{title}</h2>
        <li className="list-group">
          {teams.map((team) => (
              <TeamListElement key={team.id} team={team} />
          ))}
        </li>
      <br/>
    </>
  )
}

export default TeamList
