export const QueryParameterNames = {
  ReturnUrl: 'r',
  Message: 'message'
};

const LogoutActions = {
  Logout: 'Logout',
};

const LoginActions = {
  Login: 'Login',
  Profile: 'Profile',
  Register: 'Register'
};

const AccountActions = {
  ForgotPassword: 'ForgotPassword',
}

const TeamActions = {
  Create : 'create',
  Select : ':id',
  Modify : ':id/modify',
}
const GameActions = {
  Play : ':gameId',

}


export const Prefix = {
  Authentication : '/Authentication',
  Account : '/Account',
  Teams : '/Teams',
  Team : '/Team',
  Game : '/Game'
}


export const ApplicationPaths = {
  Login: `${Prefix.Authentication}/${LoginActions.Login}`,
  Register: `${Prefix.Authentication}/${LoginActions.Register}`,
  Profile: `${Prefix.Authentication}/${LoginActions.Profile}`,
  LogOut: `${Prefix.Authentication}/${LogoutActions.Logout}`,
  ForgotPassword: `${Prefix.Account}/${AccountActions.ForgotPassword}`,


  Teams: `${Prefix.Teams}`,
  Team : {
    Create : `${Prefix.Team}/${TeamActions.Create}`,
    Select : `${Prefix.Team}/${TeamActions.Select}`,
    Modify : `${Prefix.Team}/${TeamActions.Modify}`,
  },
  Game : {
    Play : `${Prefix.Game}/${GameActions.Play}`,
  }
};
