import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Checkbox from '../form-components/Checkbox';
import { Username } from '../form-components/Username';
import { Password } from '../form-components/Password';
import authService from '../authorization/AuthorizeService';
import ErrorResponse from '../form-components/ErrorResponse';
import { withRouter } from '../../routing/withRouter';
import { createFullPath } from '../../routing/AppRoutes';


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email : { valid : true, value : ""},
      password : { valid : true, value : ""},
      rememberMe : { value : true},
      login : null,
    };
  }
  
  routingFunction = () => {
    var redirPath = this.props.location.state?.returnUrl ?? undefined;
    this.props.navigate((redirPath === undefined) ? '/' : redirPath);
  }


  async contactSubmit(e) {
    e.preventDefault();

    var response = await authService.signIn(this.state.email.value, this.state.password.value, this.state.rememberMe.value);
    this.setState({ login : response });

    if(response.success) this.routingFunction();
  }


  render() {
    return (
      <>
        <h1>Log in</h1>
        
        <div className="row">
          <div className="col-md-4">
            <h4>Use a local account to log in.</h4>
            <hr />
            <form onSubmit={this.contactSubmit.bind(this)}>
            
              <div className="text-danger validation-summary-valid" data-valmsg-summary="true">
                <ul><li style={{display:'none'}}></li></ul>
              </div>
            
              <Username data={this.state.email} onChange={(data) => this.setState({email : data })} label="Email or Username"/>
              <Password data={this.state.password} onChange={(data) => this.setState({password : data })} />
              <Checkbox data={this.state.rememberMe} onChange={(data) => this.setState({rememberMe: data})}>Remember me?</Checkbox>

              <div className="form-group">
                <button id="login-submit" type="submit" className="btn btn-primary">Log in</button>
              </div>

              { // in case the login was not successfull => print the error response to the user
                this.state.login === null || this.state.login.success === null || this.state.login.success === true ? "" :  <ErrorResponse response={this.state.login.fault} /> 
              }

              <div className="form-group">
                <p><Link to={createFullPath("Account","ForgotPassword")}>Forgot your password?</Link></p>
                <p><Link 
                  to={createFullPath("Authentication","Register")} 
                  state={{returnUrl: this.props.location.state?.returnUrl ?? undefined}}>Register as a new user</Link></p>
              </div>
            </form>
          </div>

          <div className="col-md-6 col-md-offset-2">
            <div>
              <h4>More info during the login.</h4>
              <hr />
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Login)

