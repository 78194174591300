import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Checkbox from '../form-components/Checkbox';
import { Email } from "../form-components/Email"
import { Username } from '../form-components/Username';
import { Password } from '../form-components/Password';
import { PasswordRepeat } from '../form-components/PasswordRepeat';
import authService from '../authorization/AuthorizeService';
import ErrorResponse from '../form-components/ErrorResponse';
import { withRouter } from '../../routing/withRouter';
import { createFullPath } from '../../routing/AppRoutes';


class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username : { valid : true, value : ""},
      email : { valid : true, value : ""},
      password : { valid : true, value : ""},
      passwordRepeat : { valid : true, value : ""},
      rememberMe : { value : true},
      register : null,
    };  
  }
  
  routingFunction = () => {
    var redirPath = this.props.location.state?.returnUrl ?? undefined;
    this.props.navigate((redirPath === undefined) ? '/' : redirPath);
  }


  async contactSubmit(e) {
    e.preventDefault();

    var response = await authService.register(this.state.username.value, this.state.email.value, this.state.password.value, this.state.rememberMe.value);
    this.setState({ register : response });

    if(response.success) this.routingFunction();
  }


  render() {
    return (
      <>
        <h1>Register</h1>
        
        <div className="row">
          <div className="col-md-4">
            <h4>Create a new account.</h4>
            <hr />
            <form onSubmit={this.contactSubmit.bind(this)}>
            
              <div className="text-danger validation-summary-valid" data-valmsg-summary="true">
                <ul><li style={{display:'none'}}></li></ul>
              </div>
            
              <Username data={this.state.username} onChange={(data) => this.setState({username : data })} />
              <Email data={this.state.email} onChange={(data) => this.setState({email : data })} />
              <Password data={this.state.password} onChange={(data) => this.setState({password : data })} />
              <PasswordRepeat data={this.state.passwordRepeat} reference={this.state.password} onChange={(data) => this.setState({passwordRepeat : data })} />
              
              <Checkbox data={this.state.rememberMe} onChange={(data) => this.setState({rememberMe: data})}>Remember me?</Checkbox>

              <div className="form-group">
                <button id="login-submit" type="submit" className="btn btn-primary">Register</button>
              </div>

              { // in case the login was not successfull => print the error response to the user
                this.state.register === null || this.state.register.success === null || this.state.register.success === true ? "" :  <ErrorResponse response={this.state.register.fault} /> 
              }

              <div className="form-group">
                <p><Link to={createFullPath("Account","ForgotPassword")}>Forgot your password?</Link></p>
                <p><Link 
                  to={createFullPath("Authentication","Login")}
                  state={{returnUrl: this.props.location.state?.returnUrl ?? undefined}}>Login with existing user</Link></p>
              </div>
            </form>
          </div>

          <div className="col-md-6 col-md-offset-2">
            <div>
              <h4>Some info during register.</h4>
              <hr />
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(Register)

