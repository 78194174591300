/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DetailedGameResponse,
    DetailedGameResponseFromJSON,
    DetailedGameResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface GameApiGameIdGiveCardsAgainRequest {
    gameId: number;
}

export interface GameApiGameIdTakeGameStackgameStackRequest {
    gameId: number;
    gameStack: number;
}

export interface GameApiGetgameIdRequest {
    gameId: number;
}

export interface GameApiSetPlayCardcardIdRequest {
    cardId: number;
}

/**
 * 
 */
export class GameApi extends runtime.BaseAPI {

    /**
     */
    async gameIdGiveCardsAgainRaw(requestParameters: GameApiGameIdGiveCardsAgainRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DetailedGameResponse>> {
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId','Required parameter requestParameters.gameId was null or undefined when calling gameIdGiveCardsAgain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Game/{gameId}/giveCardsAgain`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async gameIdGiveCardsAgain(requestParameters: GameApiGameIdGiveCardsAgainRequest, initOverrides?: RequestInit): Promise<DetailedGameResponse> {
        const response = await this.gameIdGiveCardsAgainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async gameIdTakeGameStackgameStackRaw(requestParameters: GameApiGameIdTakeGameStackgameStackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DetailedGameResponse>> {
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId','Required parameter requestParameters.gameId was null or undefined when calling gameIdTakeGameStackgameStack.');
        }

        if (requestParameters.gameStack === null || requestParameters.gameStack === undefined) {
            throw new runtime.RequiredError('gameStack','Required parameter requestParameters.gameStack was null or undefined when calling gameIdTakeGameStackgameStack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Game/{gameId}/takeGameStack/{gameStack}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters.gameId))).replace(`{${"gameStack"}}`, encodeURIComponent(String(requestParameters.gameStack))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async gameIdTakeGameStackgameStack(requestParameters: GameApiGameIdTakeGameStackgameStackRequest, initOverrides?: RequestInit): Promise<DetailedGameResponse> {
        const response = await this.gameIdTakeGameStackgameStackRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getgameIdRaw(requestParameters: GameApiGetgameIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DetailedGameResponse>> {
        if (requestParameters.gameId === null || requestParameters.gameId === undefined) {
            throw new runtime.RequiredError('gameId','Required parameter requestParameters.gameId was null or undefined when calling getgameId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Game/get/{gameId}`.replace(`{${"gameId"}}`, encodeURIComponent(String(requestParameters.gameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async getgameId(requestParameters: GameApiGetgameIdRequest, initOverrides?: RequestInit): Promise<DetailedGameResponse> {
        const response = await this.getgameIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setPlayCardcardIdRaw(requestParameters: GameApiSetPlayCardcardIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DetailedGameResponse>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling setPlayCardcardId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Game/setPlayCard/{cardId}`.replace(`{${"cardId"}}`, encodeURIComponent(String(requestParameters.cardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedGameResponseFromJSON(jsonValue));
    }

    /**
     */
    async setPlayCardcardId(requestParameters: GameApiSetPlayCardcardIdRequest, initOverrides?: RequestInit): Promise<DetailedGameResponse> {
        const response = await this.setPlayCardcardIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
