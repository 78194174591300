import React from 'react'
import { generatePath } from "react-router";
import { Link } from 'react-router-dom';
import { ApplicationPaths } from '../ApiConstants'

function GameListElement({game}) {

  var joinPath = generatePath(ApplicationPaths.Game.Play, {gameId: game.id});

  return (
    <div className="list-group-item">
      <div className="row">
        <div className="col-3">{game.name}</div>
        <div className="col-9 text-center" style={{ padding: 0 }} >
          <div className="row">
            {game.points.map((points, i) => (<div className="col-1" key={i}>{points}</div>))}
            {game.points.length < 10 ? <div className={"col-" + (10 - game.points.length)}></div> : ""}
            {game.isRunningGame ? <div className="col-2"><Link className="btn btn-success float-right" to={joinPath}>Join</Link></div> : <></>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GameListElement
