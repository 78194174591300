/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryGameResponse
 */
export interface SummaryGameResponse {
    /**
     * 
     * @type {number}
     * @memberof SummaryGameResponse
     */
    readonly id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SummaryGameResponse
     */
    readonly name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SummaryGameResponse
     */
    readonly isRunningGame?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SummaryGameResponse
     */
    readonly points?: Array<number> | null;
}

export function SummaryGameResponseFromJSON(json: any): SummaryGameResponse {
    return SummaryGameResponseFromJSONTyped(json, false);
}

export function SummaryGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryGameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isRunningGame': !exists(json, 'isRunningGame') ? undefined : json['isRunningGame'],
        'points': !exists(json, 'points') ? undefined : json['points'],
    };
}

export function SummaryGameResponseToJSON(value?: SummaryGameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


