import React, { Component } from 'react'
import { withRouter } from '../../routing/withRouter';
import authService from '../authorization/AuthorizeService';

export class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {signedOut : false};
  }
    
    
  componentDidMount() {this.update(); }


  async update() {
    await authService.signOut();
    this.setState({signedOut: true})

    await this.sleep(3000);
    this.props.navigate("/");
  }
  async sleep(msec) {
    return new Promise(resolve => setTimeout(resolve, msec));
  }

  render() { return ( 
    <>
      <h2>LogOut</h2>
      <>{!this.state.signedOut ? <>Du wirst ausgeloggt...</> : 
      <>Du bist nun ausgeloggt. <br/> Bis bald 👋</>}</>
    </>)
  }
}

export default withRouter(Logout)
