/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetailedCardResponse,
    DetailedCardResponseFromJSON,
    DetailedCardResponseFromJSONTyped,
    DetailedCardResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SummaryPlayerCards
 */
export interface SummaryPlayerCards {
    /**
     * 
     * @type {string}
     * @memberof SummaryPlayerCards
     */
    readonly name?: string | null;
    /**
     * 
     * @type {DetailedCardResponse}
     * @memberof SummaryPlayerCards
     */
    playCard?: DetailedCardResponse;
    /**
     * 
     * @type {number}
     * @memberof SummaryPlayerCards
     */
    readonly penaltyPointsGame?: number;
    /**
     * 
     * @type {number}
     * @memberof SummaryPlayerCards
     */
    readonly penaltyPointsRound?: number;
}

export function SummaryPlayerCardsFromJSON(json: any): SummaryPlayerCards {
    return SummaryPlayerCardsFromJSONTyped(json, false);
}

export function SummaryPlayerCardsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryPlayerCards {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'playCard': !exists(json, 'playCard') ? undefined : DetailedCardResponseFromJSON(json['playCard']),
        'penaltyPointsGame': !exists(json, 'penaltyPointsGame') ? undefined : json['penaltyPointsGame'],
        'penaltyPointsRound': !exists(json, 'penaltyPointsRound') ? undefined : json['penaltyPointsRound'],
    };
}

export function SummaryPlayerCardsToJSON(value?: SummaryPlayerCards | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'playCard': DetailedCardResponseToJSON(value.playCard),
    };
}


