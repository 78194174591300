import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthorizeRoute from '../components/authorization/AuthorizeRoute';

export class RoutesRenderer extends Component {
  render() {
    const routes = this.props.routes;

    return (
      <Routes>
        {Object.keys(routes).map((routeKey) => {
          const route = {...routes[routeKey]};

          const routeProps = {...route.routeProps}
          const hasSubroutes = !!route.subRoutes;
          const isIndex = !!route.isIndex;
          const isIndexWithSubroutes = isIndex && hasSubroutes;

          if(isIndexWithSubroutes) routeProps.path += "/*"
          else if(isIndex && !routeProps.path) 
            routeProps.path = "/*"; // in case the path is empty at index => default
          const element = isIndexWithSubroutes ? <RoutesRenderer routes={route.subRoutes} /> :
            (!routeProps.isAuthOnly ? route.element : <AuthorizeRoute element={route.element} />)

          return <Route key={routeKey} {...routeProps} element={element} />;
        })}
      </Routes>
    );
  }
}
