/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SummaryRoundResponse,
    SummaryRoundResponseFromJSON,
    SummaryRoundResponseFromJSONTyped,
    SummaryRoundResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SummarySessionResponse
 */
export interface SummarySessionResponse {
    /**
     * 
     * @type {string}
     * @memberof SummarySessionResponse
     */
    readonly date?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SummarySessionResponse
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof SummarySessionResponse
     */
    readonly name?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SummarySessionResponse
     */
    readonly isTodaysSession?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SummarySessionResponse
     */
    readonly hasRunningRound?: boolean;
    /**
     * 
     * @type {Array<SummaryRoundResponse>}
     * @memberof SummarySessionResponse
     */
    readonly rounds?: Array<SummaryRoundResponse> | null;
}

export function SummarySessionResponseFromJSON(json: any): SummarySessionResponse {
    return SummarySessionResponseFromJSONTyped(json, false);
}

export function SummarySessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummarySessionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isTodaysSession': !exists(json, 'isTodaysSession') ? undefined : json['isTodaysSession'],
        'hasRunningRound': !exists(json, 'hasRunningRound') ? undefined : json['hasRunningRound'],
        'rounds': !exists(json, 'rounds') ? undefined : (json['rounds'] === null ? null : (json['rounds'] as Array<any>).map(SummaryRoundResponseFromJSON)),
    };
}

export function SummarySessionResponseToJSON(value?: SummarySessionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


