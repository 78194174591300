import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { generatePath } from "react-router";
import { ApplicationPaths } from '../ApiConstants'
import SechsNimmt from '../api-backend/ApiBackend'
import { createFullPath } from '../../routing/AppRoutes';

const CreateTeam = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')

  const onSubmit = (e) => {
    const doAsync = async () => {
      // do the api call
      const data = await SechsNimmt.Team.create({createTeamRequest : {name : name}});

      // go to the team view of the new team
      navigate(generatePath(createFullPath("Team", "Select"), {id: data.id}))
    }

    e.preventDefault();

    if(!name) { 
        setNameError("The team name is required")
        return
    }
    
    doAsync();
  }



  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label>Team name</label>
          <input type='text' className="form-control" placeholder="Add team name" value={name} onChange={(e) => setName(e.target.value)} />
          <small id="emailHelp" className="form-text text-danger">{nameError}&nbsp;</small>
          <br />
          <input type="submit" value="Create team" className="btn btn-primary" />
        </div>
      </form>
    </>
  )
}

export default CreateTeam
