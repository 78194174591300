import React from 'react'
import GameListElement from './GameListElement'

function RoundListElement({round, teamId}) {
  return (
    <div className="list-group-item">
      {round.name}
      <div className="list-group">
        <br/>
        {round.games.map((game) => (<GameListElement key={game.id} game={game} />))} 

        <div className="list-group-item list-group-item-secondary">
          <div className="row">
            <div className="col-3">Total</div>
            <div className="col-9 text-center" style={{ padding: 0 }}>
              <div className="row">{round.points.map((points, i) => (
                <div className="col-1" key={i}>
                  {points > 66 ? 
                    <span className="badge badge-danger badge-pill">{points}</span> :
                    <span className="badge badge-secondary badge-pill">{points}</span>
                  }
                </div>
              ))}</div>
            </div>
          </div>
        </div>

      <br/>
      </div>
    </div>
  )
}

export default RoundListElement
