import React from 'react'
import { useParams } from "react-router-dom";

function ModifyTeam() {
  let { id } = useParams();

  return (
    <>
      <h3>Modify</h3>
      Not yet implemented
      Team id: {id}
    </>
  )
}

export default ModifyTeam
