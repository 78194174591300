import React from 'react'
import SechsNimmt from '../api-backend/ApiBackend'
import { useNavigate } from 'react-router-dom'
import { ApplicationPaths } from '../ApiConstants'
import { generatePath } from "react-router";
import { createFullPath } from '../../routing/AppRoutes';

function TeamListElement({team}) {
  const navigate = useNavigate();
  const selectPath = generatePath(createFullPath("Team", "Select"), { id: team.id});
  const modifyPath = generatePath(createFullPath("Team", "Modify"), { id: team.id});
    
  const onJoin = async () => {

    if(!team.isMember) { 
      var value = await SechsNimmt.Team.jointeamId({teamId : team.id});
      if (value.success === true) { navigate(selectPath) }
    }
    else { navigate(selectPath) }
  }
  const onEdit = () => {navigate(modifyPath)}

  return (
    <div className="list-group-item">
      {team.name}
      <button className="btn btn-success float-right" onClick={onJoin}>{team.isMember ? 'Select' : 'Join'}</button>
      {team.isOwner ? <button className="btn btn-success float-right mr-2" onClick={onEdit}>Edit</button> : ""}
    </div>
  )
}

export default TeamListElement