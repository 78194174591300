/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DetailedMyselfResponse,
    DetailedMyselfResponseFromJSON,
    DetailedMyselfResponseFromJSONTyped,
    DetailedMyselfResponseToJSON,
    DetailedPileResponse,
    DetailedPileResponseFromJSON,
    DetailedPileResponseFromJSONTyped,
    DetailedPileResponseToJSON,
    SummaryPlayerCards,
    SummaryPlayerCardsFromJSON,
    SummaryPlayerCardsFromJSONTyped,
    SummaryPlayerCardsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedGameResponse
 */
export interface DetailedGameResponse {
    /**
     * 
     * @type {number}
     * @memberof DetailedGameResponse
     */
    readonly id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedGameResponse
     */
    readonly name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedGameResponse
     */
    readonly isRunningGame?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DetailedGameResponse
     */
    readonly points?: Array<number> | null;
    /**
     * 
     * @type {Array<DetailedPileResponse>}
     * @memberof DetailedGameResponse
     */
    readonly gamePiles?: Array<DetailedPileResponse> | null;
    /**
     * 
     * @type {Array<SummaryPlayerCards>}
     * @memberof DetailedGameResponse
     */
    readonly players?: Array<SummaryPlayerCards> | null;
    /**
     * 
     * @type {DetailedMyselfResponse}
     * @memberof DetailedGameResponse
     */
    myself?: DetailedMyselfResponse;
    /**
     * 
     * @type {string}
     * @memberof DetailedGameResponse
     */
    readonly status?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedGameResponse
     */
    readonly nextGame?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedGameResponse
     */
    readonly roundOver?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedGameResponse
     */
    readonly forwardToGame?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedGameResponse
     */
    readonly teamId?: number;
}

export function DetailedGameResponseFromJSON(json: any): DetailedGameResponse {
    return DetailedGameResponseFromJSONTyped(json, false);
}

export function DetailedGameResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedGameResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isRunningGame': !exists(json, 'isRunningGame') ? undefined : json['isRunningGame'],
        'points': !exists(json, 'points') ? undefined : json['points'],
        'gamePiles': !exists(json, 'gamePiles') ? undefined : (json['gamePiles'] === null ? null : (json['gamePiles'] as Array<any>).map(DetailedPileResponseFromJSON)),
        'players': !exists(json, 'players') ? undefined : (json['players'] === null ? null : (json['players'] as Array<any>).map(SummaryPlayerCardsFromJSON)),
        'myself': !exists(json, 'myself') ? undefined : DetailedMyselfResponseFromJSON(json['myself']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'nextGame': !exists(json, 'nextGame') ? undefined : json['nextGame'],
        'roundOver': !exists(json, 'roundOver') ? undefined : json['roundOver'],
        'forwardToGame': !exists(json, 'forwardToGame') ? undefined : json['forwardToGame'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
    };
}

export function DetailedGameResponseToJSON(value?: DetailedGameResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'myself': DetailedMyselfResponseToJSON(value.myself),
    };
}


