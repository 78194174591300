import React, { Component } from 'react'

export default class Imprint extends Component {
  render() {
    return (
      <>
        <h1>Impressum</h1>
        <i>Da die Website keine geschäftsmäßige Online-Dienste zur Verfügung stellt verzichte ich auf die Angabe einer Telefonnummer und Postanschrift.</i>
        <p/>
        Simon Schumacher<br/>
        E-Mail: admin@schumacher-bw.de
      </>
    )
  }
}
