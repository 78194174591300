import React from 'react'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import SechsNimmt from '../api-backend/ApiBackend'
import SessionListElement from './SessionListElement'

const Team = () => {
  let { id } = useParams();

  const [team, setTeam] = useState()
  const [loading, setLoading] = useState(true)

  // Initial load the teams, then periodically update
  useEffect(() => {
    const doAsync = async () => {
      const data = await SechsNimmt.Team.getteamId({teamId : parseInt(id)});

      setTeam(data)
      setLoading(false)
    }


    const interval=setInterval(()=>{ doAsync() },2000)
    return()=>clearInterval(interval)

  }, [id])

  return (
    <>
      {loading ? (<p><em>Loading...</em></p>) : (
        <>
          {team.isOpen ? <OpenTeam team={team} setTeam={setTeam} /> : <ClosedTeam team={team} />}
        </>)
      }
    </>
  )
}

export default Team


const OpenTeam = ({team, setTeam}) => {
  const onCloseAndPlay = () => {
    const doAsync = async () => {
      const teamNew = await SechsNimmt.Team.closeteamId({teamId: team.id})
      setTeam(teamNew)
    }

    doAsync();
  }


  return (      
    <>    
      <h3>{team.name}</h3>
      
      <h4>Player</h4>
      <br/>
      <ul className="list-group">
        {team.players.map((player) => (<li className="list-group-item" key={player.id}>{player.name}</li>))}
      </ul>

      <br/>
      {team.isOwner ? (
        <>
          <button type="button" className="btn btn-success" onClick={onCloseAndPlay}>Start playing</button>
        </>) : <></>}
    </>
  )
}

const ClosedTeam = ({team}) => {
  const onCreateSession = () => SechsNimmt.Session.createRoundteamId({teamId: team.id})

  const playerNameCellStyle = {
    height: "fit-content",
    padding: 0,
    marginTop: "100px"
  }


  return (
    <>
      <h3>{team.name}</h3>

      <div className="list-group sticky-top">
        <div className="list-group-item">
          <div className="list-group">
            <div className="list-group-item noVerticalPaddingBorder">
              <div className="list-group">
                <div className="list-group-item noVerticalPaddingBorder">
                  <div className="row">
                    <div className="col-3"></div>
                    <div className="col-9 text-center" style={{padding: "0px"}}>
                      <div className="row" style={{ paddingBottom: "10px" }}>
                        {team.players.map((player, i) => (
                          <div className="col-1 rotate-l-90" key={i} style={playerNameCellStyle}>{player.name}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="list-group">
        {team.isOwner && !team.hasTodaysSession ? (
          <div className="list-group-item">
            <button type="button" className="btn btn-success" onClick={onCreateSession}>Start new Session</button>
          </div>) : <></>}
        
        {team.sessions.length === 0 ? <em>No sessions created so far</em> : <></>}
        {team.sessions.map((session) => (<SessionListElement key={session.id} session={session} team={team} />))}
      </div>
      <br/>

    </>
  )
}