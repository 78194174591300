import React, { Component } from 'react'

export class ErrorResponse extends Component {

    render() {
        return (
            <div className="alert alert-danger" role="alert">
            <h4>{this.props.response.title}!</h4>
            <hr/>
            {Object.getOwnPropertyNames(this.props.response.errors).map(error => 
                <div key={error}>
                <h5>{error}:</h5>
                <ul>
                    {this.props.response.errors[error].map(elm => <li key={elm}>{elm}</li>)}
                </ul>
                </div>)}

            </div>
        )
    }
}

export default ErrorResponse
