/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateTeamRequest,
    CreateTeamRequestFromJSON,
    CreateTeamRequestToJSON,
    DetailedTeamResponse,
    DetailedTeamResponseFromJSON,
    DetailedTeamResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    SuccessResponse,
    SuccessResponseFromJSON,
    SuccessResponseToJSON,
    SummaryTeamResponse,
    SummaryTeamResponseFromJSON,
    SummaryTeamResponseToJSON,
} from '../models';

export interface TeamApiCloseteamIdRequest {
    teamId: number;
}

export interface TeamApiCreateRequest {
    createTeamRequest?: CreateTeamRequest;
}

export interface TeamApiGetteamIdRequest {
    teamId: number;
}

export interface TeamApiJointeamIdRequest {
    teamId: number;
}

/**
 * 
 */
export class TeamApi extends runtime.BaseAPI {

    /**
     */
    async closeteamIdRaw(requestParameters: TeamApiCloseteamIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DetailedTeamResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling closeteamId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Team/close/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedTeamResponseFromJSON(jsonValue));
    }

    /**
     */
    async closeteamId(requestParameters: TeamApiCloseteamIdRequest, initOverrides?: RequestInit): Promise<DetailedTeamResponse> {
        const response = await this.closeteamIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createRaw(requestParameters: TeamApiCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SummaryTeamResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Team/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTeamRequestToJSON(requestParameters.createTeamRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryTeamResponseFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: TeamApiCreateRequest, initOverrides?: RequestInit): Promise<SummaryTeamResponse> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getteamIdRaw(requestParameters: TeamApiGetteamIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DetailedTeamResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getteamId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Team/get/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedTeamResponseFromJSON(jsonValue));
    }

    /**
     */
    async getteamId(requestParameters: TeamApiGetteamIdRequest, initOverrides?: RequestInit): Promise<DetailedTeamResponse> {
        const response = await this.getteamIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async jointeamIdRaw(requestParameters: TeamApiJointeamIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SuccessResponse>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling jointeamId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Team/join/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseFromJSON(jsonValue));
    }

    /**
     */
    async jointeamId(requestParameters: TeamApiJointeamIdRequest, initOverrides?: RequestInit): Promise<SuccessResponse> {
        const response = await this.jointeamIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
