import React from 'react'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import SechsNimmt from '../api-backend/ApiBackend'
import GameField from './GameField'
import Card from './Card'
import { useNavigate, Link } from 'react-router-dom'
import { generatePath } from "react-router";
import { ApplicationPaths } from '../ApiConstants'
import { createFullPath } from '../../routing/AppRoutes'

function Game() {
  const navigate = useNavigate();
  let { gameId } = useParams();
  const [game, setGame] = useState()

  // Initial load the teams, then periodically update
  useEffect(() => {
    const doAsync = async () => {
      const data = await SechsNimmt.Game.getgameId({gameId: parseInt(gameId)});

      if(data.forwardToGame !== undefined) 
      {
        // go to the game we are forewarded to
        navigate(generatePath(createFullPath("Game", "Play"), {gameId: data.forwardToGame}))
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        gameId = data.forwardToGame;
      }
      else {
        // update the data
        setGame(data)
      }
    }

    const interval=setInterval(()=>{ doAsync() },2000)
    return()=>clearInterval(interval)

  }, [])

  const onSelectPlayCard = async (cardId, stackId) => {
    const data = await SechsNimmt.Game.setPlayCardcardId({cardId: cardId});
    setGame(data)
  }
  const onTakeStack = async (cardId, stackId) => {
    const data = await SechsNimmt.Game.gameIdTakeGameStackgameStack({gameId: game.id, gameStack : stackId});
    setGame(data)
  }

  const onGiveCards = async () => {
    const data = await SechsNimmt.Game.gameIdGiveCardsAgain({gameId: game.id});
  }

  const getStatus = () => {
    if(game === undefined) return "";

    

    if(game.myself.requiredActions && game.myself.requiredActions.playGameCard === true) 
      return <b className="text-danger">Please choose one of your hand cards to play... (click on a hand card to select it)</b>
    if(game.myself.requiredActions && game.myself.requiredActions.selectGameStack === true) 
      return <b className="text-danger">You have played the smallest card and you need to select one of the game stacks... (click on any card of the game stack to select it)</b>
    return game.status;
  }

 
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="jumbotron">
            <h4>Status:</h4>{getStatus()}
            {game && game.nextGame === true ? <><button className="btn btn-success float-right" onClick={onGiveCards}>Give cards</button><p></p></> : <></>}
            {game && game.roundOver === true ? <><button className="btn btn-success float-right" onClick={onGiveCards}>Start new round</button><p></p></> : <></>}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4"><Players players={game && game.players} /></div>
        <div className="col-lg-8"><GameField onTakePile={game && game.myself.requiredActions && game.myself.requiredActions.selectGameStack === true ? onTakeStack : undefined} gamePiles={game && game.gamePiles}/></div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <HandCards myself={game && game.myself} onSelectPlayCard={onSelectPlayCard} />
        </div>
      </div>
      {!game ? <></> : <Link className="btn btn-primary" to={generatePath(ApplicationPaths.Team.Select, {id : game.teamId})}>Back to session overview</Link>}
    </>
  )
}

export default Game



function Players({players}) {
  return (
    <div className="jumbotron">
      <h4>Players</h4>
      { !players ? <p><em>Loading...</em></p> : (
        <div className="row"> {players.map((player) => (<Player key={player.name} player={player} />))} </div>
      )}

    </div>
  )
}

function Player({player}) {
  return (
    <div className="col-lg-12 col-md-4 col-5 row no-margin">
      <div className="col-6 col-lg-8">
        <h6>{player.name}</h6>
        Round: {player.penaltyPointsRound} <br/>Game: {player.penaltyPointsGame}
      </div>
      <Card colwidth="4" colwidthSmallScreen="6" key={player.playCard.key} card={player.playCard} />
    </div>
  )
}





function HandCards({myself, onSelectPlayCard}) {
  
  return (
    <div className="jumbotron">
      <h4>My cards</h4> 
      { !myself ? <p><em>Loading...</em></p> : (
        <div className="row no-margin">
          <div className="col-2 d-none d-lg-block"></div>
          {myself.handCards.map((card) => (<Card colwidth="1" colwidthSmallScreen="2" key={card.key} card={card} onCardClicked={onSelectPlayCard}/>))}
        </div>
      )}
    </div>
  )
}

