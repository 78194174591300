import React from 'react'
import { Navigate } from 'react-router-dom';

// Authentication
import Login from '../components/authentication/Login';
import Logout from '../components/authentication/Logout';
import Register from '../components/authentication/Register';

import Imprint from '../components/Imprint';
import DataProtection from '../components/DataProtection';

// Teams
import Teams from '../components/Teams/Teams';
import Team from '../components/Team/Team';
// Game
import Game from '../components/Game/Game';
import ModifyTeam from '../components/Team/ModifyTeam';
import CreateTeam from '../components/Team/CreateTeam';

// predefined routes (used twice in the AppRoutes)
const AppRoutes_Recipes_Teams_path = 'Teams';

const AppRoutes = {
  Home: { element: <Navigate to={AppRoutes_Recipes_Teams_path} />, routeProps: { index: true } },

  Teams: { element: <Teams />, routeProps: {path: AppRoutes_Recipes_Teams_path, isAuthOnly: true}},
  Team: { 
    isIndex: true, routeProps: {path: "Team"},
    subRoutes: {
      Create: {element: <CreateTeam />, routeProps: {path: "create"}},
      Modify: {element: <ModifyTeam />, routeProps: {path: ":id/modify"}},
      Select: {element: <Team />, routeProps: {path: ":id"}},
    }
  },


  Game: { 
    isIndex: true, routeProps: {path: "Game"},
    subRoutes : {
      Play: {element: <Game />, routeProps: {path: ":gameId"}},
    }
  },

  
  Authentication : {
    isIndex: true, routeProps: { path: 'Authentication' },
    subRoutes : {
      Login: { element: <Login/>, routeProps: { path: 'Login' } }, 
      Register: { element: <Register/>, routeProps: { path: 'Register' } }, 
      LogOut: { element: <Logout/>, routeProps: { path: 'Logout' } }, 
    }
  },

  Account : {
    isIndex: true, routeProps: { path: 'Account' },
    subRoutes : {
      Profile: { element: <h1>Profile</h1>, routeProps: { path: 'Profile' } }, 
      ForgotPassword: { element: <h1>ForgotPassword</h1>, routeProps: { path: 'ForgotPassword' } }, 
    }
  },

  Imprint: { element: <Imprint />, routeProps: { path: 'Impressum' } },
  DataProtection: { element: <DataProtection />, routeProps: { path: 'Datenschutz' } },
};

export function createFullPath(key, subkey) {
  return "/" + AppRoutes[key].routeProps.path + "/" + AppRoutes[key].subRoutes[subkey].routeProps.path;
} 

export default AppRoutes;
