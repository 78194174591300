import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { VersionInfo } from './VersionInfo';

export class Layout extends Component {

  render () {
    return (
      <>
        <NavMenu />
        <Container>{this.props.children}</Container>
        <VersionInfo />
      </>
    );
  }
}
