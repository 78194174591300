import React from 'react'
import { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { createFullPath } from '../../routing/AppRoutes'
import { withRouter } from '../../routing/withRouter'
import authService from './AuthorizeService'

class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {ready: false, authenticated: false };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement("a");
    link.href = this.props.location.pathname;

    const loc = window.location.href.split('/');
    const returnUrl = (link.protocol === loc[0] && link.host === loc[2]) ? 
      `${link.pathname}${link.search}${link.hash}` : 
      `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const path = createFullPath("Authentication","Login")

    if (!ready) return <></>;
    else {
      if (authenticated) {
        return this.props.element
      } else {
        return <Navigate to={path} state={{returnUrl: returnUrl}}/>
      }
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}

export default withRouter(AuthorizeRoute)
