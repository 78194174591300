/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SummaryPlayerResponse,
    SummaryPlayerResponseFromJSON,
    SummaryPlayerResponseFromJSONTyped,
    SummaryPlayerResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedCardResponse
 */
export interface DetailedCardResponse {
    /**
     * 
     * @type {number}
     * @memberof DetailedCardResponse
     */
    readonly id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedCardResponse
     */
    readonly value?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailedCardResponse
     */
    readonly numHornOx?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedCardResponse
     */
    readonly isEmptySlot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedCardResponse
     */
    isNoSlot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedCardResponse
     */
    isHidden?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DetailedCardResponse
     */
    key?: number;
    /**
     * 
     * @type {SummaryPlayerResponse}
     * @memberof DetailedCardResponse
     */
    playedBy?: SummaryPlayerResponse;
}

export function DetailedCardResponseFromJSON(json: any): DetailedCardResponse {
    return DetailedCardResponseFromJSONTyped(json, false);
}

export function DetailedCardResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedCardResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'numHornOx': !exists(json, 'numHornOx') ? undefined : json['numHornOx'],
        'isEmptySlot': !exists(json, 'isEmptySlot') ? undefined : json['isEmptySlot'],
        'isNoSlot': !exists(json, 'isNoSlot') ? undefined : json['isNoSlot'],
        'isHidden': !exists(json, 'isHidden') ? undefined : json['isHidden'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'playedBy': !exists(json, 'playedBy') ? undefined : SummaryPlayerResponseFromJSON(json['playedBy']),
    };
}

export function DetailedCardResponseToJSON(value?: DetailedCardResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isNoSlot': value.isNoSlot,
        'isHidden': value.isHidden,
        'key': value.key,
        'playedBy': SummaryPlayerResponseToJSON(value.playedBy),
    };
}


