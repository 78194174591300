/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SummaryPlayerResponse,
    SummaryPlayerResponseFromJSON,
    SummaryPlayerResponseFromJSONTyped,
    SummaryPlayerResponseToJSON,
    SummarySessionResponse,
    SummarySessionResponseFromJSON,
    SummarySessionResponseFromJSONTyped,
    SummarySessionResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface DetailedTeamResponse
 */
export interface DetailedTeamResponse {
    /**
     * 
     * @type {number}
     * @memberof DetailedTeamResponse
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof DetailedTeamResponse
     */
    readonly name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedTeamResponse
     */
    readonly isOwner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedTeamResponse
     */
    readonly isMember?: boolean;
    /**
     * 
     * @type {Array<SummaryPlayerResponse>}
     * @memberof DetailedTeamResponse
     */
    readonly players?: Array<SummaryPlayerResponse> | null;
    /**
     * 
     * @type {Array<SummarySessionResponse>}
     * @memberof DetailedTeamResponse
     */
    readonly sessions?: Array<SummarySessionResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedTeamResponse
     */
    readonly isOpen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedTeamResponse
     */
    readonly hasTodaysSession?: boolean;
}

export function DetailedTeamResponseFromJSON(json: any): DetailedTeamResponse {
    return DetailedTeamResponseFromJSONTyped(json, false);
}

export function DetailedTeamResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DetailedTeamResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isOwner': !exists(json, 'isOwner') ? undefined : json['isOwner'],
        'isMember': !exists(json, 'isMember') ? undefined : json['isMember'],
        'players': !exists(json, 'players') ? undefined : (json['players'] === null ? null : (json['players'] as Array<any>).map(SummaryPlayerResponseFromJSON)),
        'sessions': !exists(json, 'sessions') ? undefined : (json['sessions'] === null ? null : (json['sessions'] as Array<any>).map(SummarySessionResponseFromJSON)),
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
        'hasTodaysSession': !exists(json, 'hasTodaysSession') ? undefined : json['hasTodaysSession'],
    };
}

export function DetailedTeamResponseToJSON(value?: DetailedTeamResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


