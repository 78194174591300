import React, { Component } from 'react'
//import validator from 'validator';

// input props:
//   > data = {value, valid}
//   > onChange(data) => Callback update props.data in parent
//  

const passwordNotValid = "Password needs at least 6 characters!";
//const strongPasswordOptions = { minLength: 6 };

export class Password extends Component {

  constructor(props) {
    super(props);

    //let valid = validator.isStrongPassword(props.data.value, strongPasswordOptions);
    let valid = props.data.value && props.data.value.length >= 6;


    let showValidation = !(props.data.value == null || props.data.value === "");
    let validationText = (valid || !showValidation ? null : passwordNotValid);

    this.state = {
      strValidationText : validationText,
      blnShowValidation : showValidation,
    };

    if(valid !== props.data.valid) {
      props.onChange(props.data.value, valid);
    }
  } // constructor(props)


  doValidation = (password, blnShowValidation) => {
    let valid = password && password.length >= 6;

    if (typeof blnShowValidation === 'undefined') {
      this.setState({
        strValidationText : (valid || !this.state.blnShowValidation ? null : passwordNotValid),
      });

    } else {
      this.setState({
        strValidationText : (valid ? null : passwordNotValid),
        blnShowValidation : blnShowValidation,
      });

    } 

    this.props.onChange({ valid : valid, value : password});
  } // doValidation = (password, blnShowValidation)




  render() {
    return (
      <div className="form-group">
        <label>Password</label>
        <input className="form-control" type="password" value={this.props.data.value || ""} onChange={(e) => this.doValidation(e.target.value)} onBlur={(e) => this.doValidation(e.target.value, true)}/>
        <span className="text-danger field-validation-valid">{this.state.strValidationText}</span>
      </div>
    )
  } // render()

} // export class Password extends Component

export default Password
