import React, { Component } from 'react';
import { Layout } from './components/Layout';
import AppRoutes from './routing/AppRoutes';
import './custom.css';
import { RoutesRenderer } from './routing/RoutesRenderer';

export default class App extends Component {

  render () {
    return (
      <Layout>
        <RoutesRenderer routes={AppRoutes} />
      </Layout>
    );
  }
}
