/* tslint:disable */
/* eslint-disable */
/**
 * SechsNimmt
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SummaryTeamResponse,
    SummaryTeamResponseFromJSON,
    SummaryTeamResponseFromJSONTyped,
    SummaryTeamResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface SummaryTeamCollection
 */
export interface SummaryTeamCollection {
    /**
     * 
     * @type {Array<SummaryTeamResponse>}
     * @memberof SummaryTeamCollection
     */
    myTeams?: Array<SummaryTeamResponse> | null;
    /**
     * 
     * @type {Array<SummaryTeamResponse>}
     * @memberof SummaryTeamCollection
     */
    openTeams?: Array<SummaryTeamResponse> | null;
}

export function SummaryTeamCollectionFromJSON(json: any): SummaryTeamCollection {
    return SummaryTeamCollectionFromJSONTyped(json, false);
}

export function SummaryTeamCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryTeamCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'myTeams': !exists(json, 'myTeams') ? undefined : (json['myTeams'] === null ? null : (json['myTeams'] as Array<any>).map(SummaryTeamResponseFromJSON)),
        'openTeams': !exists(json, 'openTeams') ? undefined : (json['openTeams'] === null ? null : (json['openTeams'] as Array<any>).map(SummaryTeamResponseFromJSON)),
    };
}

export function SummaryTeamCollectionToJSON(value?: SummaryTeamCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'myTeams': value.myTeams === undefined ? undefined : (value.myTeams === null ? null : (value.myTeams as Array<any>).map(SummaryTeamResponseToJSON)),
        'openTeams': value.openTeams === undefined ? undefined : (value.openTeams === null ? null : (value.openTeams as Array<any>).map(SummaryTeamResponseToJSON)),
    };
}


